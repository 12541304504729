import Events from './events/index.js'
import State from './state/index.js'

export default class MouseoverCustomers {
  constructor(elem, opts) {
    this.target = elem
    this.opts = opts

    // customers
    this.customers = this.target.querySelectorAll('[data-mouseovercustomers-target]')

    // staffs (staff + concierge)
    this.staffsCntr = this.target.getElementsByClassName('p-top__kv__top__picture__staffs')[0]

    this.activeStaffId = null
    this.fukidashiActivePersonIndex = -1 //吹き出しがアクティブな人のindex
    this.stepIntervalId

    this.controlClass = {
      fukidashi: "is-fukidashi--active",
      color: "is-color--active",
      lightColor: "is-light--active"
    }

    this.init()
  }

  init() {

    this.eventBinds()
  }

  eventBinds() {
    window.addEventListener(Events.END_KEYVISUAL_ANIMATION, () => {
      // 0. 最初のカスタマーのふきだしをセットする
      this.customers[this.fukidashiActivePersonIndex + 1].classList.add(this.controlClass.fukidashi);
      // 0-0. sp - 初期アクティブなスタッフをセット
      if(State.currentDevice === 'sp') {
        this.activeStaffId = this.customers[0].getAttribute('data-mouseovercustomers-target')
        this.toggleStaff(this.activeStaffId)
      }

      // 吹き出しの自動制御
      this.autoChangeFukidashi()

      // pc- マウスオーバー&リーブ制御
      if(State.currentDevice === 'pc') {
        this.judge()
      }
    })
  }

  judge() {
    // マウスオーバー判定
    for(let i = 0; i < this.customers.length; i++){
      this.customers[i].addEventListener('mouseover', ()=> {
        if(State.currentDevice === 'pc') {
          this.toggleCustomer(i)

          const targetStaffId = this.customers[i].getAttribute('data-mouseovercustomers-target')
          this.activeStaffId = targetStaffId
          this.toggleStaff(targetStaffId)

          // ふきだしアクティブを一時変更
          this.toggleFukidashi(this.customers[i], i)
        }
      })
    }

    // マウスリーブ判定
    for(let i = 0; i < this.customers.length; i++){
      this.customers[i].addEventListener('mouseleave', ()=> {
        if(State.currentDevice === 'pc') {
          // 0. アクティブを外す
          this.toggleCustomer(null)
          this.toggleStaff(null)

          // 1. ふきだしを最後にマウスオーバーした人に設定
          this.toggleFukidashi(this.customers[i], i)
          // 2. ふきだし自動移動を発火
          this.autoChangeFukidashi(i)
        }
      })
    }
  }

  toggleCustomer(activeIndex) {
    for(let i = 0; i < this.customers.length; i++){
      if (activeIndex === null) {
        this.customers[i].classList.remove(this.controlClass.lightColor)
        this.customers[i].classList.add(this.controlClass.color)
      } else if(i !== activeIndex) {
        // マウスオーバーしてない人から、 .is-color--active を外し、 .is-light--active を付ける
        this.customers[i].classList.remove(this.controlClass.color)
        this.customers[i].classList.add(this.controlClass.lightColor)
      }
    }
  }

  toggleStaff(id) {
    if(id === null) {
      const removeClass = 'is-' + this.activeStaffId + '--active'
      this.staffsCntr.classList.remove(removeClass)
      this.staffsCntr.classList.remove('is-concierge--active')
    } else if(id) {
      if(id === '0') {
        const controlClass = 'is-concierge--active'
        this.staffsCntr.classList.add(controlClass)
      } else {
        const controlClass = 'is-' + id + '--active'
        this.staffsCntr.classList.add(controlClass)
      }
    }
  }

  /**
   * @method toggleFukidashi
   * @desc ふきだしアクティブの変更
   */
  toggleFukidashi(target, index) {
    // 1. 起動してる自動移動を解除
    clearInterval(this.stepIntervalId);

    // 2. targetがふきだしアクティブでない場合
    if(!target.classList.contains(this.controlClass.fukidashi)) {

      // 2-1. ふきだしがアクティブなものから、アクティブ管理クラスを外す
      for(let i = 0; i < this.customers.length; i++) {
        if(this.customers[i].classList.contains(this.controlClass.fukidashi)) {
          this.customers[i].classList.remove(this.controlClass.fukidashi)
        }
      }

      // 2-2. targetに アクティブ管理クラスを付与
      target.classList.add(this.controlClass.fukidashi)

      // 2-3. ふきだしアクティブパーソンindexを更新
      this.fukidashiActivePersonIndex = index
    }
  }

  /**
   * @method autoChangeFukidashi
   * @desc ふきだしの自動移動
   * @note index...ふきだしアクティブを開始する人を指定する場合につかう引数
   */
  autoChangeFukidashi(index) {
    const _this = this

    if(index >= 0) {
      this.fukidashiActivePersonIndex = index - 1
    }

    // NOTE: 初回は5s後に発火する
    const step = function() {
      // 1-0.
      _this.fukidashiActivePersonIndex++;

      let currentIndex = _this.fukidashiActivePersonIndex % _this.customers.length;
      let nextNumIndex = (_this.fukidashiActivePersonIndex + 1) % _this.customers.length;
      let currentPerson = _this.customers[currentIndex];
      let nextPerson = _this.customers[nextNumIndex];

      // 1-1. activeなふきだしを非activeにする
      currentPerson.classList.remove(_this.controlClass.fukidashi);

      if(State.currentDevice === 'sp') {
        //      スタッフのアクティブを解除
        _this.toggleStaff(null)
      }

      // 1-2. 次のふきだしをactiveにする
      nextPerson.classList.add(_this.controlClass.fukidashi);

      if(State.currentDevice === 'sp') {
        //      スタッフをアクティブにする
        const currentStaffId = _this.customers[nextNumIndex].getAttribute('data-mouseovercustomers-target')
        _this.activeStaffId = currentStaffId
        _this.toggleStaff(_this.activeStaffId)
      }
    }

    // 1. 5sごとにふきだしを移動させる
    this.stepIntervalId = setInterval(step, 4000);
  }
}