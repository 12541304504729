const Events = new class Events {
  //*** eventをDispatchするときのevent管轄
  constructor() {
    //+++ KVアニメーション完了
    this.END_KEYVISUAL_ANIMATION = 'END_KEYVISUAL_ANIMATION'

    return this
  }

  /**
  * @method dispatch
  * @desc eventのdispatch
  * @param {String} el - event name
  */
  dispatch(event) {
    window.dispatchEvent(
      new CustomEvent(event)
    )
  }
}

module.exports = Events
