import GSAP from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

export default class Anchor {
  constructor(el) {
    this.el = el

    this.threshold = 0

    this.target = null
    this.touchFlg = false
    this.select = false
    this.scrollSpeed = 1

    GSAP.registerPlugin(ScrollToPlugin)

    this.init()
  }

  init() {
    this.bindEvents()
  }

  bindEvents() {
    window.addEventListener('load', () => {
      this.target = this.el.getAttribute('href').replace('#', '')
      this.target = this.target ? document.getElementById(this.target) : null
    })

    if(this.el) {
      this.el.addEventListener('click', e => {
        if(!this.touchFlg) {
          e.preventDefault()
          e.stopPropagation()
          this.move()
        }
      })
    }
  }

  move() {
    let yPos = 0
    this.select = true

    if(this.target) {
      yPos = window.pageYOffset + this.target.getBoundingClientRect().top - this.threshold
    }

    GSAP.to(window, {
      duration: this.scrollSpeed,
      ease: 'Power2.easeOut',
      scrollTo: { y: yPos, autoKill: false }
    })
  }
}
