import Events from './events/index.js'

import gsap from 'gsap'

export default class Opening {
  constructor(elem, opts) {
    this.target = elem
    this.opts = opts

    this.topBaloons = this.target.getElementsByClassName('p-top__kv__top__picture__balloons__itm')
    this.topCustomers = this.target.getElementsByClassName('p-top__kv__top__picture__customers__itm')

    this.skipBtn = this.target.getElementsByClassName('p-top__kv__top__picture__detail__btn')[0]

    this.tl = gsap.timeline()
    this.anim1 = null

    this.init()
  }

  init() {
    this.eventBinds()
  }

  eventBinds() {
    const _this = this

    window.addEventListener('load', () => {
      // kvアニメーションスタート
      _this.anim()
    })

    this.skipBtn.addEventListener('click', () => {
      this.allDisplay()
    })
  }

  anim() {
    const _this = this

    gsap.to('.p-top__kv__top__picture__logo--cntr', {
      delay: 2,
      opacity: 0,
      duration: 1,
      ease: "power4.out",
      onComplete: function() {
        // SKIPボタンの表示
        gsap.to('.p-top__kv__top__picture__detail__btn--cntr', {
          duration: 1,
          ease: "power4.out",
          opacity: 1,
          pointerEvents: "auto"
        })

        // バルーンアニメーション
        _this.tl.to('.p-top__kv__top__picture__balloons__itm', {
          keyframes:[
            {
              opacity: 1,
              scale: 1.1,
              pointerEvents: "auto",
              duration: 1,
              ease: "power4.out"
            },
            {
              opacity: 0,
              scale: 1,
              pointerEvents: "auto",
              duration: 1.5,
              ease: "power4.out"
            }
          ],
          stagger: {
            from: "start",
            amount: 5 // 5秒おきに
          }
        });
        //       ボタンを非表示
        _this.tl.to('.p-top__kv__top__picture__detail__btn--cntr', {
          opacity: 0,
          pointerEvents: "none"
        })
        //       ふきだしを表示
        _this.tl.to(['.p-top__kv__top__picture__staffs__concierge__fukidashi'], {
          opacity: 1,
          y:0,
          pointerEvents: "auto",
          duration: .4,
          onComplete: function() {
            document.getElementsByClassName('p-top__kv__top__picture__staffs__concierge')[0].classList.add('is-fukidashi--active')

            Events.dispatch(Events.END_KEYVISUAL_ANIMATION)
          }
        })

        // カスタマーアニメーション
        _this.anim1 = gsap.to('.p-top__kv__top__picture__customers__itm', {
          delay: 0.8,
          opacity: 1,
          pointerEvents: "auto",
          duration: 1,
          ease: "power4.out",
          stagger: {
            delay: 1,
            from: "start",
            amount: 5
          },
          onComplete: function() {
            gsap.to(['.p-top__kv__top__picture__staffs', '.p-top__kv__bg'], {
              duration: 1,
              ease: "power4.out",
              opacity: 1,
              pointerEvents: "auto"
            })
          }
        });
      }
    })
  }

  allDisplay() {
    const _this = this

    _this.tl.pause()
    _this.anim1.pause()

    gsap.set([
      '.p-top__kv__top__picture__balloons__itm',
      '.p-top__kv__top__picture__detail__btn--cntr'
    ], {
      opacity: 0,
      display: "none",
      pointerEvents: "none"
    })

    gsap.to([
      '.p-top__kv__top__picture__customers__itm',
      '.p-top__kv__top__picture__staffs',
      '.p-top__kv__bg'
    ], {
      duration: 0.66,
      ease: "power3.out",
      opacity: 1,
      pointerEvents: "auto"
    })

    gsap.to([
      '.p-top__kv__top__picture__staffs__concierge__fukidashi'
    ], {
      opacity: 1,
      pointerEvents: "auto",
      y:0,
      onComplete: function() {
        document.getElementsByClassName('p-top__kv__top__picture__staffs__concierge')[0].classList.add('is-fukidashi--active')
        Events.dispatch(Events.END_KEYVISUAL_ANIMATION)
      }
    })
  }
}