import Events from './events/index.js'
import State from './state/index.js'

import ModalBackfaceControl from './ModalBackfaceControl';

import gsap from 'gsap'

export default class Keyvisual {
  constructor(elem, opts) {
    this.target = elem
    this.opts = opts

    this.topCustomers = this.target.getElementsByClassName('p-top__kv__top__picture__customers__itm')
    this.storyCustomers = this.target.getElementsByClassName('p-top__kv__story__list__itm')

    this.concierge = this.target.getElementsByClassName('p-top__kv__top__picture__staffs__concierge')[0]
    this.conciergeModal = this.target.getElementsByClassName('p-top__kv__modal--wrp')[0]
    this.conciergeModalOverlay = this.target.getElementsByClassName('p-top__kv__modal__overlay')[0]
    this.conciergeModalClose = this.target.getElementsByClassName('p-top__kv__modal__close')[0]
    this.conciergePulldown = this.target.getElementsByClassName('p-top__kv__modal__select')[0]

    this.conciergePulldownInrMenu = this.target.getElementsByClassName('p-top__kv__modal__menu-list')[0]

    this.pulldownmenus = this.target.getElementsByClassName('p-top__kv__modal__menu-list__itm__btn')

    this.storyActiveCustomerIndex = null

    this.displayStoryFlg = false
    this.conciergePulldownInrMenuOpenFlg = false //プルダウン自体の白背景メニュー
    this.conciergePulldownInrMenuActiveIndex = 0

    this.controlClass = {
      concierge: "is-concierge--active",
      pulldownInrMenu: "is-pulldown--active"
    }

    this.modalBackfaceControl = new ModalBackfaceControl();

    this.init()
  }

  init() {
    // リンクメニューリストの初期値設定
    this.setLinkList(this.conciergePulldownInrMenuActiveIndex)

    this.eventBinds()
  }

  eventBinds() {
    window.addEventListener('resize', () => {
      const _this = this

      if(_this.displayStoryFlg) {
        if(State.currentDevice === 'sp') {
          _this.target.style.height = _this.storyCustomers[_this.storyActiveCustomerIndex].clientHeight + 'px'
        } else {
          _this.target.style.height = _this.storyCustomers[_this.storyActiveCustomerIndex].getElementsByClassName('p-top__kv__story__list__itm__detail')[0].clientHeight + 124 + 'px'
        }
      }
    })

    window.addEventListener(Events.END_KEYVISUAL_ANIMATION, () => {
      console.log("END_KEYVISUAL_ANIMATION");

      Array.prototype.forEach.call(this.topCustomers, (person,index) => {
        // ホバーアクション許可
        person.classList.add('is-hov--active')

        // カスタマーをクリック
        person.addEventListener('click', ()=> {
          // 0. コンシェルジュメニューモーダルを閉じる
          this.conciergeModal.classList.remove(this.controlClass.concierge)

          // 1. クリックされた人のindex番号を、ストーリーがアクティブなカスタマーを管理する変数に代入
          this.storyActiveCustomerIndex = index

          // 2. カスタマーのストーリーパネルに
          let customerkey
          if(index === 0) {
            customerkey = 'a'
          } else if(index === 1) {
            customerkey = 'b'
          } else if(index === 2) {
            customerkey = 'c'
          } else if(index === 3) {
            customerkey = 'd'
          }
          this.startStory(index, customerkey)
        })
      })

      // コンシェルジュをクリック
      this.concierge.addEventListener('click', ()=> {
        // プルダウンメニューの初期値設定
        this.setActivePulldown(this.conciergePulldownInrMenuActiveIndex)

        this.toggleConciergeModal(true)
      })

      // コンシェルジュメニューモーダルの閉じるトリガーをクリック
      this.conciergeModalOverlay.addEventListener('click', ()=> {
        this.toggleConciergeModal(false)
      })
      this.conciergeModalClose.addEventListener('click', ()=> {
        this.toggleConciergeModal(false)
      })

      // プルダウンメニューをクリック
      this.conciergePulldown.addEventListener('click', ()=> {
        if(!this.conciergePulldownInrMenuOpenFlg) {
          // プルダウンインナーメニューを開く
          this.togglePulldownInrMenu(true)
        } else {
          // プルダウンインナーメニューを閉じる
          this.togglePulldownInrMenu(false)

        }
      })

      // プルダウンインナーメニュー内の選択肢をクリック
      Array.prototype.forEach.call(this.pulldownmenus, (item,index) => {
        item.addEventListener('click', ()=> {
          // 0. アクティブなプルダウン選択肢をセット
          this.conciergePulldownInrMenuActiveIndex = index
          // 0. アクティブなプルダウンを透過に
          this.setActivePulldown(index)

          // 1. プルダウンメニュー本体のラベルを選択肢に変更
          this.conciergePulldown.innerHTML = item.innerHTML

          // 2. プルダウンインナーメニュー(白地)自体を閉じる
          this.togglePulldownInrMenu(false)

          // 3. リンクメニューリスト内容を変更
          this.setLinkList(this.conciergePulldownInrMenuActiveIndex)
        })
      })

    })
  }

  scrollControll(ban) {
    if(ban) {
      document.addEventListener('touchmove', this.bindFunction, { passive: false });
      document.addEventListener('mousewheel', this.bindFunction, { passive: false });
    } else {
      document.removeEventListener('touchmove', this.bindFunction, { passive: false });
      document.removeEventListener('mousewheel', this.bindFunction, { passive: false });
    }
  }

  /**
   * @method toggleConciergeModal
   * @desc コンシェルジュモーダルの開閉トグル
   */
  toggleConciergeModal(openFlg) {
    if(openFlg) {
      this.conciergeModal.classList.add(this.controlClass.concierge)
      // this.modalBackfaceControl.backfaceFixed(true) //背面スクロール禁止
    } else {
      this.conciergeModal.classList.remove(this.controlClass.concierge)
      // this.modalBackfaceControl.backfaceFixed(false)
    }
  }

  /**
   * @method togglePulldownInrMenu
   * @desc プルダウン内選択肢メニューの開閉トグル
   */
  togglePulldownInrMenu(openFlg) {
    if(openFlg) {
      this.conciergeModal.classList.add(this.controlClass.pulldownInrMenu)
      this.conciergePulldownInrMenuOpenFlg = true
    } else {
      this.conciergeModal.classList.remove(this.controlClass.pulldownInrMenu)
      this.conciergePulldownInrMenuOpenFlg = false
    }
  }

  /**
   * @method setActivePulldown
   * @desc アクティブな選択肢の切り替え
   */
  setActivePulldown(index) {
    const pulldownMenuLists = this.target.getElementsByClassName('p-top__kv__modal__menu-list__itm')
    for(let i = 0; i < pulldownMenuLists.length; i++){
      if(i == index) {
        pulldownMenuLists[i].classList.add('is--active')
      } else {
        pulldownMenuLists[i].classList.remove('is--active')
      }
    }
  }

  /**
   * @method setLinkList
   * @desc 選択されたプルダウンメニューに該当するリンクリストを表示
   */
  setLinkList(index) {
    const linkLists = this.target.getElementsByClassName('p-top__kv__modal__link-list')

    for(let i = 0; i < linkLists.length; i++){
      if(i == index) {
        linkLists[i].classList.add('is--active')
      } else {
        linkLists[i].classList.remove('is--active')
      }
    }
  }

  /**
   * @method startStory
   * @desc storyエリアのアニメーション
   */
  startStory(index) {
    const _this = this

    let target = _this.storyCustomers[index]

    const trobleCustomerWrpClass = 'p-top__kv__story__list__itm__picture__customer--trouble'
    const trobleCustomerFigureClass = 'p-top__kv__story__list__itm__picture__customer--trouble__figure'

    const customerWrpClass = 'p-top__kv__story__list__itm__picture__customer--cntr'
    const customerPersonClass = 'p-top__kv__story__list__itm__picture__customer__figure'

    const staffWrpClass = 'p-top__kv__story__list__itm__picture__staff--cntr'
    const staffPersonClass = 'p-top__kv__story__list__itm__picture__figure--staff'

    const detailClass = 'p-top__kv__story__list__itm__detail'
    const gobackBtnClass = 'p-top__kv__story__list__itm__picture__btn--cntr'

    const activeControlClass = 'is--'+_this.storyActiveCustomerIndex

    const target_1 = [
      '.p-top__kv__top',
      '.p-top__kv__bg',
      '.p-top__kv__top__picture__staffs__concierge__fukidashi'
    ]

    const target_2 = [
      target.getElementsByClassName(detailClass),
      target.getElementsByClassName(gobackBtnClass)
    ]

    const target_3 = [
      '.p-top__kv__story',
      target,
      target.getElementsByClassName(trobleCustomerWrpClass),
      target.getElementsByClassName(trobleCustomerFigureClass)
    ]
    // NOTE: troubleCustomerElemsを含んでる

    const staffElems = [
      target.getElementsByClassName(staffWrpClass),
      target.getElementsByClassName(staffPersonClass)
    ]

    const troubleCustomerElems = [
      target.getElementsByClassName(trobleCustomerWrpClass),
      target.getElementsByClassName(trobleCustomerFigureClass)
    ]

    const customerElems = [
      target.getElementsByClassName(customerWrpClass),
      target.getElementsByClassName(customerPersonClass)
    ]


    // 0. 対象カスタマー以外をdisplay: none; にしておく
    _this.target.getElementsByClassName('p-top__kv__story')[0].classList.add(activeControlClass)

    gsap.to('.p-top__kv__story', {
      display: "block"
    })

    gsap.to(troubleCustomerElems, {
      scale: 0.6,
    })

    gsap.to(target_1, {
      // topエリアをフェードアウトさせる
      opacity: 0,
      pointerEvents: "none",
      duration: 0.27,
      onComplete: function() {
        _this.displayStoryFlg = true

        if(State.currentDevice === 'sp') {
          // STORYエリアの高さを維持するために、
          // 親要素であるKVエリア自体をSTORYエリアの高さに合わせる
          gsap.to('.p-top__kv', {
            height: target.clientHeight + 'px',
            onComplete: function() {
              gsap.to('.p-top__kv__top', {
                display: "none"
              })
            }
          })
        } else {
          // クリッカブルを回避
          gsap.to('.p-top__kv__top__picture', {
            display: "none"
          })

          // ストーリーエリアの高さを維持するために、
          // 親要素であるKVエリア自体を左エリアのテキストエリアの高さに合わせる
          const detailCntr = target.getElementsByClassName(detailClass)[0]
          gsap.to('.p-top__kv', {
            height: detailCntr.clientHeight + 124 + 'px'
          })
        }

        // +++ detail テキストエリアが出現
        gsap.to(target.getElementsByClassName(detailClass), {
          opacity: 1,
          pointerEvents: "auto",
          duration: 0.33,
          onComplete: function() {
            gsap.to(target.getElementsByClassName(gobackBtnClass), {
              opacity: 1,
              pointerEvents: "auto",
              duration: 0.33
            })

            // +++ 困ったカスタマー

            // 困ったカスタマー表示
            // --- storyエリア自体と表示対象ストーリーエリア、困ったカスタマーの箱が出現
            // --- 1.83sは困ったアニメーションしてる
            gsap.to(target_3, {
              opacity: 1,
              scale: 1,
              pointerEvents: "auto",
              duration: 0.66,
              ease: "power3.out",
              onComplete: function() {
                // +++ スタッフが出現する
                _this.staffAppear = gsap.to([
                  target.getElementsByClassName(staffWrpClass),
                  target.getElementsByClassName(staffPersonClass)
                ], {
                  delay: 1,
                  opacity: 1,
                  scale: 1,
                  pointerEvents: "auto",
                  duration: 0.66,
                  onComplete: function() {
                    // +++ 解決カスタマー
                    
                    // 解決カスタマー表示
                    gsap.to([
                      target.getElementsByClassName(customerWrpClass),
                      target.getElementsByClassName(customerPersonClass)
                    ], {
                      // delay: 0.3,
                      opacity: 1,
                      scale: 1,
                      pointerEvents: "auto",
                      duration: 0.66,
                      ease: "power3.out",
                      onComplete: function() {
                        // 困ったカスタマーが小さくなる
                        gsap.to(troubleCustomerElems, {
                          // delay: 0.33,
                          // scale: 0.6,
                          opacity: 0,
                          pointerEvents: "none",
                          duration: 0.66
                        })
                      }
                    })

                    // // 困ったカスタマーが小さくなる
                    // gsap.to(troubleCustomerElems, {
                    //   delay: 0.33,
                    //   scale: 0.6,
                    //   pointerEvents: "none",
                    //   duration: 0.66,
                    //   ease: "power3.out",
                    //   onComplete: function() {
                    //     gsap.to(troubleCustomerElems, {
                    //       opacity: 0
                    //     })
                    //   }
                    // })
                  }
                })

              }
            })
          }
        })
      }
    })

    // トップ状態にもどる
    target.getElementsByClassName(gobackBtnClass)[0].addEventListener('click', ()=> {
      // gsap.to(target_3, {
      //   opacity: 0,
      //   scale: 0,
      //   pointerEvents: "none"
      // })

      _this.staffAppear.kill()
      _this.staffAppear = null

      gsap.to('.p-top__kv__top', {
        display: "flex"
      })

      gsap.to('.p-top__kv__story', {
        opacity: 0,
        duration: 0.66,
        onComplete: function() {

          _this.target.getElementsByClassName('p-top__kv__story')[0].classList.remove(activeControlClass)

          gsap.to('.p-top__kv', {
            height: 'auto',
            onComplete: function() {
              gsap.to(target_1, {
                opacity: 1,
                pointerEvents: "auto"
              })
    
              gsap.to('.p-top__kv__story', {
                display: "none"
              })
    
              if(State.currentDevice === 'pc') {
                gsap.to('.p-top__kv__top__picture', {
                  display: "block"
                })
              }
            }
          })
        }
      })

      _this.displayStoryFlg = false

      gsap.to(target_2, {
        opacity: 0,
        duration: 0.66,
        pointerEvents: "none"
      })


      gsap.to(staffElems, {
        opacity: 0,
        scale: 0.6,
        duration: 0.66,
        pointerEvents: "none"
      })

      gsap.to(customerElems, {
        opacity: 0,
        scale: 0.6,
        duration: 0.66,
        pointerEvents: "none"
      })

      // モーダルを閉じておく
      this.conciergeModal.classList.remove(this.controlClass.concierge)
    })
  }
}