export default class MenuModalControl {
  constructor(elem, opts) {
    this.target = elem
    this.modalWrapElm = document.getElementById('burgerMenuWrap')
    this.burgerMenuBtnElm = document.getElementById('burgerMenuBtn')
    this.eventBinds()
  }

  eventBinds() {
    const _this = this
    window.addEventListener('load', ()=> {
      this.target.addEventListener('click', ()=>{
        _this.changeModalState()
      })
    })
  }

  changeModalState() {
    if(this.modalWrapElm.classList.contains('is-open')){
      this.burgerMenuBtnElm.classList.remove('is-open')
      this.modalWrapElm.classList.remove('is-open')
    }else{
      this.burgerMenuBtnElm.classList.add('is-open')
      this.modalWrapElm.classList.add('is-open')
    }
  }
}