// NOTE: CustomEvent ie9以上対応用ポリフィル
(function () {
  if (typeof window.CustomEvent === 'function') return false
  function CustomEvent (event, params) {
    params = params || {bubbles: false, cancelable: false, detail: undefined}
    var evt = document.createEvent( 'CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
   }
  CustomEvent.prototype = window.Event.prototype
  window.CustomEvent = CustomEvent
})()

// import '@babel/polyfill'
import './modules/index.js'