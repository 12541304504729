/**
 * @returns {Class} New Constructor
 */
Array.prototype.forEach.call(document.querySelectorAll('[data-module]'), element => {
  const keys = element.getAttribute('data-module').split(/\s+/)
  const opts = element.getAttribute('data-options') || null

  keys.forEach(key => {
    const Module = require(`./${key}`).default
    const options = opts ? (keys.length > 1 ? JSON.parse(opts)[key] : JSON.parse(opts)) : {}

    if (Module !== void 0) return new Module(element, options)
  })

  // NOTE: 以下、dynamic import使う場合
  // keys.forEach(key => {
  //   import(/* webpackMode: "eager" */`./${key.charAt(0).toUpperCase() + key.slice(1)}`)
  //   .then(
  //     module => {
  //       const options = opts ? keys.length > 1 ? JSON.parse(opts)[key] : JSON.parse(opts) : {}
  //       if (module !== void 0) return new module.default(element, options)
  //     }
  //   )
  //   .catch(err => {
  //     // console.log('module not found', key)
  //   })
  // })
})
