export default class NewsTabControl {
  constructor(elem, opts) {
    this.target = elem
    this.tabBtnElms = this.target.querySelectorAll('[data-newstabbtn]')
    this.tabContentElms = this.target.querySelectorAll('[data-newstabcontent]')
    this.eventBinds()
  }

  eventBinds() {
    const _this = this
    window.addEventListener('load', ()=> {
      for(let i=0; i<_this.tabBtnElms.length; i++){
        _this.tabBtnElms[i].addEventListener('click', ()=>{
          _this.changeTabContent(_this.tabBtnElms[i].dataset.newstabbtn)
        })
      }
    })
  }

  changeTabContent(category) {
    for(let i=0; i<this.tabBtnElms.length; i++){
      if(this.tabBtnElms[i].dataset.newstabbtn === category){
        this.tabBtnElms[i].classList.add('is-active')
      }else{
        this.tabBtnElms[i].classList.remove('is-active')
      }
    }

    for(let i=0; i<this.tabContentElms.length; i++){
      if(this.tabContentElms[i].dataset.newstabcontent === category){
        this.tabContentElms[i].classList.add('is-active')
      }else{
        this.tabContentElms[i].classList.remove('is-active')
      }
    }
  }
}