export default class BackToTop {
  constructor(el) {
    this.el = el
    this.innerHeight = window.innerHeight
    this.target = document.querySelector('[data-back-to-top-target]')
    this.inactiveArea = document.querySelector('[data-content-wrap]')
    this.activeClass = 'is-active'
    this.absoluteClass = 'is-absolute'
    this.init()
  }
  init() {
    this.bindEvents()
    console.log('init')
  }
  bindEvents() {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 10) {
        this.inactivate()
      } else {
        this.activate()
      }
      if (0 > this.inactiveArea.getBoundingClientRect().bottom - this.innerHeight) {
        this.changeAbsolute()
      } else {
        this.changeFixed()
      }
    })
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
      if (window.scrollY < 10) {
        this.inactivate()
      } else {
        this.activate()
      }
      if (0 > this.inactiveArea.getBoundingClientRect().bottom - this.innerHeight) {
        this.changeAbsolute()
      } else {
        this.changeFixed()
      }
    })
  }
  activate() {
    this.target.classList.add(this.activeClass)
  }
  inactivate() {
    this.target.classList.remove(this.activeClass)
  }
  changeAbsolute() {
    this.target.classList.add(this.absoluteClass)
  }
  changeFixed() {
    this.target.classList.remove(this.absoluteClass)
  }
}