export default class MenuAccordionControl {
  constructor(elem, opts) {
    this.elem = elem
    this.opts = opts
    this.triggerElm = this.elem.getElementsByClassName('js-accordion-trigger')[0]
    this.target = this.elem.querySelectorAll('[data-accordiontarget="' + this.opts.target + '"]')[0]
    this.targetInnerElm = this.target.children[0]
    this.targetHeight = this.targetInnerElm.clientHeight
    this.target.style.height = '0px'
    this.eventBinds()
  }

  eventBinds() {
    const _this = this
    window.addEventListener('load', ()=> {
      this.triggerElm.addEventListener('click', ()=>{
        _this.changeAccordionState()
      })
    })
  }

  changeAccordionState() {
    this.targetHeight = this.targetInnerElm.clientHeight
    if(this.triggerElm.classList.contains('is-open')){
      this.triggerElm.classList.remove('is-open')
      this.triggerElm.classList.add('is-no-click')
      this.target.style.height = this.targetHeight + 'px'
      setTimeout(() => {
        this.triggerElm.classList.remove('is-no-click')
        this.target.style.height = '0px'
      }, 100)
    }else{
      this.triggerElm.classList.add('is-open')
      this.triggerElm.classList.add('is-no-click')
      this.target.style.height = this.targetHeight + 'px'
      setTimeout(() => {
        this.triggerElm.classList.remove('is-no-click')
        this.target.style.height = 'auto'
      }, 500)
    }
  }
}