// Utility methods
module.exports = class Utils {
  // -----------------------------------------------------------------
  //
  // @method Utils.setLocalStorage()
  // localStorage設定
  //
  // -----------------------------------------------------------------
  static setLocalStorage(key, val) {
    localStorage.setItem(key, val)
  }
  // -----------------------------------------------------------------
  //
  // @method Utils.removeLocalStorage()
  // localStorage設定 - keyを削除
  //
  // -----------------------------------------------------------------
  static removeLocalStorage(key, val) {
    localStorage.removeItem(key)
  }

  // -----------------------------------------------------------------
  //
  // @method Utils.getLocalStorage()
  // localStorage取得
  //
  // -----------------------------------------------------------------
  static getLocalStorage(key) {
    let result = localStorage.getItem(key)
    return result
  }
}